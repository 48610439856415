import {Router} from "@profiscience/knockout-contrib-router";
import {Idea, IdeaDto, IdeaMemberDto, UserDto} from "../../api/generated";
import {campaignApi, ideaApi, userApi} from "../../api/api-wrapper";
import {autobind, observable, unwrap} from "knockout-decorators";
import * as ko from "knockout";
import {postbox} from "../../components/util/postbox";
import globalState from "../../global-state";
import {ideaAttachmentsRegex} from "../ideas/ideaUtils";
import {IdeaEditViewModel, IdeaEditViewModelContext} from "./IdeaEditViewModel";
import {config} from "../../utils/clientConfigWrapper";
import StateEnum = Idea.StateEnum;


class ViewModelContext extends IdeaEditViewModelContext {
    adminContacts: UserDto[];
}

class ViewModel extends IdeaEditViewModel {

    /**
     * The admin contact user.
     */
    @observable({deep: false, expose: false})
    public adminContacts: UserDto[];

    /**
     * Passed to the tabs binding, to jump to the first pane with errors.
     */
    public lastValidation: KnockoutObservable<Date>;

    public termsAccepted: KnockoutObservable<boolean>;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super(ctx);
        // Admin Users will not see inactive campaigns when creating an idea
        this.campaigns = this.campaigns.filter(campaign => this.isCampaignActive(campaign));
        this.adminContacts = ctx.adminContacts;
        this.lastValidation = ko.observable(new Date());

        unwrap(this.idea, "title").extend({required: true, maxlength: 255});
        unwrap(this.idea, "description").extend({required: true, maxlength: 65535});
        unwrap(this.idea, "advantage").extend({required: true, maxlength: 65535});
        unwrap(this.idea, "audience").extend({required: true, maxlength: 65535});

        this.termsAccepted = ko.observable(false);

        console.debug("Idea: ", this.idea, ctx.idea);
        console.debug("Admin contact: ", this.adminContacts);
    }

    /**
     * Adds an idea member with the role Submitter.
     */
    public addIdeaMember() {
        super.addMember(IdeaMemberDto.RoleEnum.Submitter);
    }

    /**
     * Saves the idea without officially submitting it.
     * Sets the state to submission and saves the idea.
     * The idea will not be listed public.
     */
    @autobind
    public save() {
        this.idea.state = IdeaDto.StateEnum.Submission;
        return this.saveIdea("save");
    }

    /**
     * Validate and submits the idea.
     * Sets the state to submitted and saves the idea.
     */
    @autobind
    public submit() {
        const errors = ko.validation.group(this.idea)
        if (errors().length > 0) {
            errors.showAllMessages();
            this.lastValidation(new Date());
            postbox.addError('validation.failed');
            return;
        }

        if (this.termsEnabled() && !this.termsAccepted()) {
            postbox.addError('global.error.termsAccepted');
            return;
        }

        this.idea.state = IdeaDto.StateEnum.Submitted;
        this.idea.statechanged = new Date();
        // created date is set to the day when the idea is submitted and relevant for further actions and notifications.
        // e.g. admin has to create an evaluation within 7 days.
        this.idea.created = new Date();

        return this.saveIdea("submit");
    }

    /**
     * Discards the idea.
     * Sets the deleted flag and saves the idea.
     */
    @autobind
    public discard() {
        if (this.idea.id == null) {
            return Router.update(`/idee`, {
                push: true,
                force: false
            });
        } else {
            this.idea.deleted = true;
            return this.saveIdea("discard");
        }
    }

    /**
     * save the idea.
     */
    @autobind
    private saveIdea(i18nSuffix: string) {
        if (this.idea.id == null) {
            this.idea.statechanged = new Date();
            this.idea.created = new Date();
            globalState.loading(true);

            // remember state for put operation
            const state = this.idea.state;

            return this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    this.idea.uploads = uploads)
                .then(() => {
                    // set state to submission -> does not trigger notification, score and awards
                    this.idea.state = StateEnum.Submission;
                    return ideaApi.postIdea(this.idea);
                })
                .then(idea => {
                    this.idea = idea;
                    return this.saveMembers();
                })
                .then(() => {
                    this.idea.state = state;
                    return ideaApi.putIdea(this.idea.id, this.idea);
                })
                .then(() => {
                    postbox.addSuccess(`idea.create.success.${i18nSuffix}`);
                    return Router.update(`/idee`, {
                        push: true,
                        force: false
                    });
                })
                .catch(err => {
                    console.error(err);
                    postbox.addError(`idea.create.error.${i18nSuffix}`);
                })
                .finally(() =>
                    globalState.loading(false));

        } else {
            return this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    this.idea.uploads = uploads)
                .then(() => this.saveMembers())
                .then(() =>
                    ideaApi.putIdea(this.idea.id, this.idea))
                .then(() => {
                    postbox.addSuccess(`idea.create.success.${i18nSuffix}`);
                    return Router.update(`/idee`, {
                        push: true,
                        force: false
                    });
                })
                .catch(err => {
                    console.error(err);
                    postbox.addError(`idea.create.error.${i18nSuffix}`);
                })
                .finally(() =>
                    globalState.loading(false));
        }
    }

    /**
     * Dispose subscriptions.
     */
    public dispose() {
        super.dispose();
    }

    public terms(): string {
        return config.terms;
    }

    public termsEnabled(): boolean {
        return config.termsAlways && typeof config.terms !== 'undefined';
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./create.html'),
    componentName: "create",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;
        const ideaPromise = (id == null) ? Promise.resolve(null) :
            ideaApi.getIdea(ctx.params.id).then(idea => ctx.idea = idea);
        const membersPromise = (id == null) ? Promise.resolve(null) :
            ideaApi.getIdeaMembers(ctx.params.id).then(members => ctx.members = members);

        document.title = `${document.title} - Idee Einreichen ${ctx.params && ctx.params.id || ''}`;

        return Promise.all([
            ideaPromise,
            membersPromise,
            campaignApi.getCampaigns().then(campaigns => ctx.campaigns = campaigns),
            userApi.getAdminUsers().then(users => ctx.adminContacts = users)
        ]);
    }
};
